import css from 'styled-jsx/css';

export default css`.like-container {
  cursor: pointer;
  float: left;
  vertical-align: middle;
  position: relative;
  padding: 5px 0;
  margin-top: -5px;
  margin-bottom: -5px;
  z-index: 1; }
  .like-container .like-num {
    float: right;
    margin-left: 3px;
    line-height: 18px;
    font-size: .8em;
    color: #6d6d6d; }
  .like-container .like-char {
    float: right;
    margin-left: 3px;
    line-height: 18px;
    font-size: .8em;
    color: #6d6d6d; }
  .like-container .like-icon {
    position: relative;
    float: right;
    width: 18px;
    height: 18px;
    margin-top: 0;
    background-image: url("https://static-staging.popdaily.com.tw/img/globalicon/like01.png");
    background-size: cover;
    background-position: center;
    margin-right: 1px;
    animation-duration: 3s;
    animation-delay: 2s;
    animation-iteration-count: infinite; }
    .like-container .like-icon.like-icon-active {
      background-image: url("https://static-staging.popdaily.com.tw/img/globalicon/like02.png"); }
    .like-container .like-icon :global(.svg-heart) {
      display: block;
      transform: scale(3) !important; }
      .like-container .like-icon :global(.svg-heart) > :global(g) > :global(g):nth-of-type(1) {
        display: none !important; }
  .like-container.postBottom {
    vertical-align: middle; }
    .like-container.postBottom .like-num,
    .like-container.postBottom .like-char,
    .like-container.postBottom .like-icon {
      font-size: 1em;
      margin: 0 2px; }
    .like-container.postBottom .like-icon {
      background-image: url("/statics/images/icon/heart@3x.png");
      background-size: 100%;
      background-repeat: no-repeat; }
      .like-container.postBottom .like-icon.like-icon-active {
        background-image: url("https://static-staging.popdaily.com.tw/img/globalicon/like02.png"); }
  .like-container.article-like-result-number {
    clear: both;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 16px 0 0; }
    .like-container.article-like-result-number .like-num {
      order: 3;
      clear: both; }
    .like-container.article-like-result-number .like-char {
      display: none; }
    .like-container.article-like-result-number .like-icon {
      order: 1;
      width: 20px;
      height: 20px;
      clear: both;
      background-image: url("https://static-staging.popdaily.com.tw/img/globalicon/like03.png"); }
      .like-container.article-like-result-number .like-icon.like-icon-active {
        background-image: url("https://static-staging.popdaily.com.tw/img/globalicon/like04.png"); }
`